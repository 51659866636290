<template>
    <div class="trading-top">
        <div class="cur-currency">
            <div class="left">
                <div class="name" @click="openAside">
                    <img class="menu" src="@/assets/images/h5/trade/menu.png" />
                    {{curCoin.name + '/USDT'}}
                </div>
                <div class="price">
                    <div class="close" :style="{ color: parseFloat(curCoin.price.close) >= parseFloat(curCoin.price.open) ? '#01BD8B' : '#FB474E'}">{{parseFloat(curCoin.price.close)}}</div>
                    <div class="valuation">≈ {{parseFloat(curCoin.price.close)}}$</div>
                </div>
            </div>
            <div class="right">
                <div class="item">
                    <div class="label">{{$t('Trade.Change')}}</div>
                    <div class="value" :style="{ color: parseFloat(curCoin.price.close) >= parseFloat(curCoin.price.open) ? '#01BD8B' : '#FB474E'}">
                        {{parseFloat(curCoin.price.close) >= parseFloat(curCoin.price.open) ? '+' : ''}}{{((parseFloat(curCoin.price.close) - parseFloat(curCoin.price.open))/ parseFloat(curCoin.price.open) * 100).toFixed(2)}}%
                    </div>
                </div>
                <div class="item">
                    <div class="label">{{$t('Trade.Highest')}}</div>
                    <div class="value">{{parseFloat(curCoin.price.high)}}</div>
                </div>
                <div class="item">
                    <div class="label">{{$t('Trade.Lowest')}}</div>
                    <div class="value">{{parseFloat(curCoin.price.low)}}</div>
                </div>
            </div>
        </div>
        <tradingAside ref="tradingAside" />
        <el-tabs v-model="tabsValue" type="card" closable @tab-remove="removeTab">
            <el-tab-pane v-for="(item,index) in tabList" :key="item.name" :label="item.title" :name="item.name">
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    export default {
        components: {
            tradingAside: () => import('./aside.vue')
        },
        data() {
            return {
                tabsValue: '',
                tabList: [],
                isShowAside: false
            }
        },
        created() {
            // console.log('created')
            this.$store.commit('setCurTime','15min')
        },
        destroyed() {
            // console.log('destroyed')
            this.$ws.sendMsg(`{"type":"leave","data":"kline:${this.curCoin.key}:${this.curTime}"}`)
        },
        computed: {
            connect() {
                return this.$store.state.connect
            },
            
            curCoin() {
                return this.$store.state.curCoin
            },
            
            curTime() {
                return this.$store.state.curTime
            }
        },
        watch: {
            curCoin(new_val,old_val) {
                // console.log(new_val.key,old_val.key)
                this.addTab(new_val)
                if(old_val.key) {
                    this.$ws.sendMsg(`{"type":"leave","data":"kline:${old_val.key}:${this.curTime}"}`)
                }
                this.$ws.sendMsg(`{"type":"join","data":"kline:${new_val.key}:${this.curTime}"}`)
            },
            
            connect(value) {
                let val = JSON.parse(value)
                if(val.status) {
                    if(this.curCoin.key && val.type != 'first') {
                        this.$ws.sendMsg(`{"type":"join","data":"kline:${this.curCoin.key}:${this.curTime}"}`)
                    }
                }
            },
            
            tabsValue(val) {
                this.tabList.forEach(item => {
                    if(val == item.name) {
                        this.$store.commit('setCurCoin',item.coin_info)
                    }
                })
            }
        },
        methods: {
            addTab(val) {
                let isRepeat = false
                this.tabList.forEach(item => {
                    if(val.key == item.name) {
                        isRepeat = true
                    }
                })
                
                if(!isRepeat) {
                    this.tabList.push({
                        name: val.key,
                        title: val.name,
                        coin_info: val
                    })
                    this.tabsValue = val.key
                } else {
                    this.tabsValue = val.key
                }
            },
            
            removeTab(targetName) {
                // console.log(targetName)
                let tabs = this.tabList
                let activeName = this.tabsValue
                if (activeName === targetName) {
                    tabs.forEach((tab, index) => {
                        if (tab.name === targetName) {
                            let nextTab = tabs[index + 1] || tabs[index - 1]
                            if (nextTab) {
                                activeName = nextTab.name
                            }
                        }
                    })
                }
            
                this.tabsValue = activeName
                this.tabList = tabs.filter(tab => tab.name !== targetName)
            },
            
            openAside() {
                this.$refs.tradingAside.open()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .trading-top {
        width: 100%;
        
        .cur-currency {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            box-sizing: border-box;
            padding: 1.6rem 1.2rem;
            margin-bottom: 4px;
            background-color: #FFFFFF;
            
            .left {
                width: 48%;
                
                .name {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 2rem;
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #000000;
                    word-break: break-all;
                    
                    .menu {
                        min-width: 1.5rem;
                        max-width: 1.5rem;
                        height: 1.5rem;
                        margin-right: 10px;
                    }
                }
                
                .price {
                    width: 100%;
                    
                    .close {
                        width: 100%;
                        margin-bottom: 4px;
                        font-size: 1.9rem;
                        font-weight: bold;
                        word-break: break-all;
                    }
                    
                    .valuation {
                        width: 100%;
                        font-size: 1.2rem;
                        color: #6D6F7E;
                        word-break: break-all;
                    }
                }
            }
            
            .right {
                width: 45%;
                
                .item {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    
                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                    
                    .label {
                        max-width: 48%;
                        font-size: 1.2rem;
                        color: #6D6F7E;
                        word-wrap: break-word;
                    }
                    
                    .value {
                        max-width: 48%;
                        font-size: 1.3rem;
                        color: #000000;
                        word-break: break-all;
                    }
                }
            }
        }
        
        /deep/ .el-tabs--card>.el-tabs__header {
            border: 0;
            margin: 0;
        }
        
        /deep/ .el-tabs__nav-wrap {
            margin: 0;
        }
        
        /deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
            border: 0;
        }
        
        /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item {
            height: 3.5rem;
            line-height: 3.5rem;
            border: 0;
            padding: 0 1.2rem;
            font-size: 1.3rem;
            color: #000000;
        }
        
        /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
            background-color: #FFFFFF;
            border: 0;
            color: #5466EF;
        }
    }
</style>
